import GoogleAuthComponent from '../components/Auth/GoogleAuth';
import logo from '../assets/logo/docsautomator-icon.svg';
import { authenticator } from '../services/auth.server';
import { json, useLoaderData, useFetcher, Link } from '@remix-run/react';
import { sessionStorage } from '../services/session.server';
import { useEffect, useState } from 'react';

export async function loader({ request }) {
  await authenticator.isAuthenticated(request, {
    successRedirect: '/',
  });
  let session = await sessionStorage.getSession(request.headers.get('cookie'));
  let error = session.get(authenticator.sessionErrorKey);
  return json(
    { error },
    {
      headers: {
        'Set-Cookie': await sessionStorage.commitSession(session), // You must commit the session whenever you read a flash
      },
    }
  );
}

export async function action({ request }) {
  // articifial delay ...
  await new Promise((resolve) => setTimeout(resolve, 1000));

  return await authenticator.authenticate('form-login', request, {
    successRedirect: '/',
    failureRedirect: '/login',
    throwOnError: true,
  });
}

export default function login() {
  const loaderData = useLoaderData();
  const loginFetcher = useFetcher();
  const [error, setError] = useState('');
  useEffect(() => {
    if (loaderData?.error?.message) {
      setError(loaderData?.error.message || '');
    }
  }, [loaderData]);
  return (
    <div className="min-h-screen flex justify-center items-center">
      <div className="bg-white flex flex-col items-center shadow-md rounded-2xl">
        <div className="lg:min-w-[450px] min-w-[350px] px-8 py-12 flex flex-col gap-4">
          <div className="flex flex-col items-center justify-center w-full">
            <img className="w-16 h-16" src={logo} alt="DocsAutomator Logo" />
            <h1 className="text-2xl font-bold my-4">DocsAutomator</h1>
          </div>
          <GoogleAuthComponent caller="login" />
          <div className="flex items-center">
            <div className="flex-grow border-t border-gray-300"></div>
            <span className="mx-4 text-gray-400">OR</span>
            <div className="flex-grow border-t border-gray-300"></div>
          </div>
          <loginFetcher.Form
            className="flex flex-col gap-4 w-full max-w-sm"
            method="post"
          >
            <input
              type="email"
              name="email"
              required
              placeholder="Email"
              autoComplete="on"
              className="w-full border border-slate-200 rounded px-3 py-2"
            />
            <input
              type="password"
              name="password"
              required
              placeholder="Password"
              autoComplete="on"
              className="w-full border border-slate-200 rounded px-3 py-2"
            />
            <button
              type="submit"
              onClick={() => setError('')}
              className="inline-flex items-center justify-center space-x-1 min-h-10 py-2 px-4 bg-indigo-600 text-white rounded hover:bg-indigo-700 hover:shadow-sm transition-all duration-200"
            >
              {loginFetcher.state === 'loading' ||
              loginFetcher.state === 'submitting' ? (
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 38 38"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke="#fff"
                >
                  <g fill="none" fillRule="evenodd">
                    <g transform="translate(1 1)" strokeWidth="2">
                      <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
                      <path d="M36 18c0-9.94-8.06-18-18-18">
                        <animateTransform
                          attributeName="transform"
                          type="rotate"
                          from="0 18 18"
                          to="360 18 18"
                          dur="1s"
                          repeatCount="indefinite"
                        />
                      </path>
                    </g>
                  </g>
                </svg>
              ) : (
                <span>Login</span>
              )}
            </button>
            {error && <p className="text-red-500 text-sm">{error}</p>}
          </loginFetcher.Form>
          <Link
            className="self-start mt-4 text-indigo-600 text-sm underline"
            to="/password-reset"
          >
            Forgot your password?
          </Link>
          <p className="self-start mt-4 text-sm">
            Don't have a DocsAutomator account yet?{' '}
            <Link
              className="self-start mt-4 text-indigo-600 underline"
              to="/signup"
            >
              SignUp
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}
